import React, {Suspense, useContext} from "react";
import "./linkedin.scss"; // Change to your LinkedIn-specific styles
import Loading from "../loading/Loading";
import {twitterDetails as linkedinDetails} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

const renderLoader = () => <Loading />;
const cantDisplayError =
  "<div className='centerContent'><h2>Can't load? Check privacy protection settings</h2></div>";

function timeOut() {
  setTimeout(function () {
    if (!document.getElementById("linkedin").innerHTML.includes("iframe")) {
      document.getElementById("linkedin").innerHTML = cantDisplayError;
    }
  }, 10000);
}

export default function LinkedIn() {
  const {isDark} = useContext(StyleContext);

  if (!linkedinDetails.display) {
    return null;
  }
  if (!linkedinDetails.postUrl) {
    console.error("LinkedIn post URL for LinkedIn section is missing");
    return null; // Return null if the post URL is missing
  }

  return (
    <Suspense fallback={renderLoader()}>
      <div
        className={`li-main-div ${isDark ? "dark-mode" : "light-mode"}`}
        id="linkedin"
      >
        <div className="centerContent">
          <iframe
            src={`https://www.linkedin.com/embed/feed/update/${linkedinDetails.postUrl}`}
            height="400"
            width="100%"
            frameBorder="0"
            allowFullScreen
            title="LinkedIn Post"
            onLoad={timeOut}
          ></iframe>
        </div>
      </div>
    </Suspense>
  );
}
