import React from "react";
import "./cat.scss";

export default function Cat() {
  return (
    <div class="cat-container-running">
      <div class="sprite-cat-vertical">
        <div class="heart-icon"></div>
      </div>
    </div>
  );
}
