import React from "react";
import "./loading.scss";

export default function Loading() {
  return (
    <div className="centerContent">
      <div className="sk-circle">
        <svg class="spinner" viewBox="0 0 100 100" height="150px" width="150px">
          <clipPath id="clip3">
            <path d="M 43.694984,19.925946 44.437766,19.893651 45.374318,19.441523 47.053652,18.246612 48.506922,17.277765 49.637243,16.922521 H 50.864449 L 52.156245,17.180881 53.38345,17.826778 52.511488,17.955958 51.801001,18.666445 51.574937,19.570702 51.607232,20.668729 51.639526,21.73446 51.445757,22.832486 50.993629,23.995102 50.638385,23.769038 50.250846,23.220025 50.057077,22.703306 48.668397,25.642141 47.990204,25.448372 47.376601,24.737884 46.859883,23.704448 46.698409,22.057409 46.730703,20.765613 46.536934,20.248895 46.18169,19.990536 45.697267,19.893651 45.180549,20.055126 44.405471,20.248895 43.824163,20.184305 Z" />
            <path d="M 25.674436,27.805899 27.54754,27.321476 28.613271,26.740168 29.420643,26.15886 29.00081,26.09427 28.645566,25.739026 28.354912,25.060833 28.225732,23.930512 28.354912,22.767896 28.710156,21.443806 29.259169,20.34578 30.034246,20.636434 30.841618,21.314626 31.648991,22.348063 32.101119,23.349204 32.424068,23.833628 33.005376,24.091987 33.845043,24.156576 34.81389,23.542974 35.524377,22.929371 36.105685,22.283473 36.07339,22.897076 36.13798,23.510679 36.299454,24.188871 36.170275,24.57641 35.718146,24.899359 35.266018,24.867064 34.910774,24.963949 34.7493,25.157718 34.781595,25.448372 35.007659,25.739026 35.427492,25.835911 35.944211,25.932795 36.234865,26.126565 36.364044,26.449514 35.911916,26.255744 35.524377,26.223449 35.007659,26.320334 34.426351,26.707873 33.29603,27.224591 31.84276,27.515245 29.485233,27.644424 27.127706,27.805899 Z" />
            <path d="M 60.655324,22.143375 62.48733,22.223027 63.721943,21.904418 64.677772,21.506156 66.230994,21.386677 69.178134,21.625634 70.890661,22.023896 71.727012,22.62129 71.01014,22.700942 70.054311,23.059378 69.735701,23.537293 70.213615,23.975381 70.930487,25.409125 71.687185,26.763216 72.404057,27.400435 73.081103,27.639393 74.036932,27.87835 72.961624,27.958002 71.567707,27.918176 69.735701,27.280957 68.301957,26.40478 66.828387,25.010862 65.673427,23.816076 65.036208,23.13903 64.598119,22.939899 H 63.841421 L 63.164376,23.13903 62.248373,23.099204 61.133239,22.820421 60.615498,22.541637 Z" />
            <path d="M 68.421436,46.556844 69.21796,46.716149 69.974658,46.517018 70.572051,45.919625 70.890661,45.123101 71.408402,44.685012 72.364231,44.326576 73.00145,44.167271 73.041277,43.848662 72.563362,43.4504 72.085448,43.410573 71.607533,43.370747 72.523536,42.454744 73.160755,42.056482 73.997106,41.937003 75.11224,42.175961 74.79363,40.901522 74.873283,39.188995 75.391023,37.356989 76.227374,35.485157 77.382334,33.215063 78.019553,31.343231 78.57712,29.829834 78.696599,28.953658 79.53295,29.551051 80.448953,31.064447 80.807388,32.458365 80.887041,34.409849 80.966693,36.719769 V 39.746562 L 80.68791,41.220132 80.289648,42.096308 79.771907,42.653875 79.21434,43.012311 79.134687,43.291095 79.293992,43.649531 80.329474,43.848662 81.962349,43.928314 82.918178,43.888488 83.953659,43.689357 V 44.764665 L 83.63505,45.800146 82.878352,46.835628 82.002175,47.393195 80.847215,47.831283 79.891386,47.791457 79.572776,46.755975 79.015209,46.039104 78.457642,45.720494 77.621291,45.680668 76.426505,46.039104 75.152066,46.795802 73.439539,47.433021 71.687185,47.5525 70.133963,47.353369 68.979003,47.074585 Z" />
            <path d="M 72.547118,59.319829 73.081103,59.301233 73.917453,58.863144 74.355542,58.664013 75.191892,58.783492 75.789285,58.902971 76.346852,58.624187 76.904419,58.305577 77.700944,58.265751 78.417816,57.708184 78.776252,56.991312 78.89573,56.234614 79.811733,56.433745 80.68791,57.031139 81.086172,57.74801 81.00652,58.863144 80.090517,59.619843 79.254166,59.938452 78.736425,60.018105 78.776252,60.496019 79.094861,60.69515 79.811733,61.01376 80.807388,61.212891 81.803044,61.292544 81.922522,61.690806 81.84287,62.328025 81.364955,62.965245 80.847215,63.164376 79.971038,63.044897 79.293992,62.566982 78.816078,62.009415 78.377989,61.770458 77.900075,61.730632 77.42216,62.288199 76.944246,63.044897 75.988417,63.921074 73.877627,65.394644 72.523536,65.952211 71.488054,66.151342 70.532225,66.031863 71.288923,65.076034 71.84649,64.000726 73.081103,62.208546 74.036932,61.252717 74.47502,60.814629 74.514847,60.416367 74.275889,60.057931 73.598844,59.779147 Z" />
            <path d="M 61.59399,67.789597 H 62.197459 L 62.961852,67.910291 63.766476,68.473528 64.530869,69.157459 65.2148,69.278152 65.858499,69.076996 66.341274,68.835609 66.86428,68.87584 67.387285,69.278152 69.19769,71.16902 70.726476,73.18058 71.088557,74.307054 71.370176,75.312835 71.329944,76.238153 71.088557,76.720927 70.967863,76.560002 70.686245,75.554222 69.921852,74.588673 68.956303,74.025436 67.910291,74.105898 66.86428,74.347286 68.111447,75.795609 68.674684,76.680696 V 77.083008 L 68.111447,77.887632 66.944742,78.491101 65.89873,78.772719 65.094106,78.611794 64.691794,78.370407 64.329713,77.968095 64.410175,74.990985 61.875609,75.916303 60.628441,76.680696 59.864048,77.646245 59.501967,78.652026 59.180117,79.45665 58.697343,79.939424 58.415724,80.261274 V 80.422199 L 58.576649,80.663586 58.536418,80.864742 H 58.254799 L 57.369713,79.496881 57.007632,78.12902 57.208788,76.600234 58.093875,74.025436 59.542198,71.732257 60.346823,70.324164 61.151447,69.881621 61.754915,69.720696 62.076765,69.680465 62.197459,69.19769 62.076765,68.634453 Z" />
            <path d="M 35.121851,66.984973 34.679307,67.990754 34.478151,68.916071 V 69.801158 L 34.719539,71.329944 35.363238,72.657575 36.851793,75.674916 37.334568,76.519771 37.656417,76.720927 38.05873,76.761158 38.461042,76.47954 38.621967,76.439309 38.702429,76.600234 37.938036,77.606014 36.570175,78.692257 35.644857,79.215263 35.121851,79.295725 35.041388,79.537112 34.920695,80.180812 35.162082,80.663586 35.564394,81.146361 36.248325,81.186592 36.972487,80.824511 37.696649,79.939424 38.380579,79.175031 38.903585,78.611794 39.949597,78.330176 42.04162,78.008326 44.012949,77.163471 45.622198,75.83584 46.708441,74.789829 46.788903,74.186361 46.66821,73.864511 H 46.426822 L 46.265897,74.347286 45.581967,74.749598 44.616418,74.709366 43.972718,74.548442 43.892256,74.387517 44.214105,73.985205 44.69688,73.582892 45.219886,73.50243 44.214105,73.220812 43.168094,73.261043 42.36347,73.582892 41.800233,73.703586 41.357689,73.582892 40.794452,72.617343 39.587516,71.209251 38.018498,69.841389 36.248325,68.513759 35.443701,67.749366 Z" />
            <path d="M 28.282545,64.89295 25.82844,63.88717 25.104278,63.484857 24.661735,63.002083 V 62.318152 L 24.903122,60.829597 25.466359,57.892719 V 56.323701 L 25.224972,55.27769 24.541041,54.392603 23.937573,54.110984 23.293874,54.151216 22.609943,54.754684 21.483469,56.484626 19.994914,59.823817 19.713295,60.829597 19.793758,62.197459 20.19607,63.203239 20.920232,63.726245 21.081157,64.007863 19.552371,64.168788 18.22474,64.048094 17.581041,63.88717 17.299423,64.088326 17.058035,64.772256 16.816648,65.818268 17.21896,66.663123 17.983353,67.186129 H 18.787977 L 19.632833,66.502199 20.356995,66.059655 21.000694,65.938962 21.684625,66.743586 22.609943,67.507979 24.219192,68.393066 26.110059,68.714915 27.47792,68.553991 28.081388,68.19191 28.403238,67.507979 28.282544,67.467748 27.638845,67.427517 26.83422,66.783817 26.190521,65.697574 25.908903,65.415956 Z" />
            <path d="M 26.954914,34.920695 25.747978,36.771331 25.50659,37.254105 25.466359,37.656417 25.82844,38.340348 26.029596,39.38636 25.707746,41.840464 25.064047,44.656649 24.299654,45.903816 23.253642,46.829134 22.489249,47.150984 22.609943,46.547516 22.569712,45.622198 22.408787,44.898036 V 43.892256 L 22.489249,42.966938 22.85133,42.04162 22.770868,41.116302 22.368556,40.512834 21.724856,40.311678 21.322544,40.39214 21.121388,41.116302 20.719076,41.880695 20.19607,42.162313 19.512139,42.202545 18.667284,41.558845 18.144278,40.311678 V 39.38636 L 18.586821,38.903585 19.471908,38.621967 20.155839,38.340348 20.316764,37.777111 20.115608,37.173643 19.471908,36.851793 18.908671,36.972487 17.902891,37.495492 18.22474,36.208094 19.150058,34.880464 20.51792,33.914914 21.805319,33.552833 23.454798,34.035608 24.219192,34.920695 24.50081,35.685088 25.50659,35.162082 26.270983,35.08162 Z" />
          </clipPath>
          <path
            class="path"
            clip-path="url(#clip3)"
            d="M 69.953283,51.366174 C 69.19877,62.386066 59.653719,70.707801 48.633826,69.953283 37.613934,69.19877 29.292199,59.653719 30.046717,48.633826 30.80123,37.613934 40.346281,29.292199 51.366174,30.046717 62.386066,30.80123 70.707801,40.346281 69.953283,51.366174"
          />
        </svg>
      </div>
    </div>
  );
}
